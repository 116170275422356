import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "environments/environment";
import { AppModule } from "app/app.module";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2a2bd087b87a46afacacd3a7ad4c7df5@o1014701.ingest.sentry.io/5986973",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://dev-api.releaseit.com.au", "https://api.releaseit.com.au"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
