import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

declare let gtag: Function;
declare let _cio: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "G-NPQZNZVJ4H", {
          page_path: event.urlAfterRedirects,
        });

        // This is only a relative URL but we need the full URL
        _cio.page(event.urlAfterRedirects, { site: "Business Portal" });
      }
    });
  }
}
