import { Injectable } from "@angular/core";
import { GetCategoryGQL } from "generated/graphql";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private readonly getCategoryQuery: GetCategoryGQL) {}

  async getCategory(id: string) {
    const res = await this.getCategoryQuery
      .fetch({
        categoryId: id,
      })
      .toPromise();
    if (res.data.Category.__typename === "GenericError") throw new Error("Unable to fetch category");
    return res.data.Category.category;
  }
}
