import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { environment } from "environments/environment";

@Component({
  selector: "app-cms-page",
  templateUrl: "./cms-page.component.html",
  styleUrls: ["./cms-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsPageComponent implements OnInit {
  urlSlug: string = null;
  title: string = null;
  loading = true;
  body: string = null;
  notFound = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private c: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.urlSlug = params["urlSlug"];
      this.fetchPage();
    });
  }

  fetchPage() {
    const payload = {
      query: `
          query CmsPage($urlSlug: String!) {
            pages(where: { UrlSlug: $urlSlug }) {
              Body
              Heading
            }
          }
        `,
      variables: {
        urlSlug: this.urlSlug,
      },
      operationName: "CmsPage",
    };

    this.http.post<any>(environment.cmsEndpoint, payload).subscribe(({ data }) => {
      try {
        this.loading = false;
        if (data.pages.length < 1) {
          this.notFound = true;
          return;
        }
        this.title = data.pages[0].Heading;
        this.body = data.pages[0].Body;
      } finally {
        this.c.detectChanges();
      }
    });
  }

  addVideoEmbeds(content: string) {
    if (!content) return null;
    const regex = /\[https:\/\/www.loom.com\/share\/([0-9a-f]{32})\]/g;
    const ne = content.replace(
      regex,
      `<div style="position: relative; padding-bottom: 48.4375%; height: 0;"><iframe src="https://www.loom.com/embed/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`
    );
    return this.sanitizer.bypassSecurityTrustHtml(ne);
  }
}
