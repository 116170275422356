import { AcademyMockApi } from "app/mock-api/apps/academy/api";
import { ActivitiesMockApi } from "app/mock-api/pages/activities/api";
import { AnalyticsMockApi } from "app/mock-api/dashboards/analytics/api";
import { AuthMockApi } from "app/mock-api/common/auth/api";
import { CalendarMockApi } from "app/mock-api/apps/calendar/api";
import { ChatMockApi } from "app/mock-api/apps/chat/api";
import { ContactsMockApi } from "app/mock-api/apps/contacts/api";
import { ECommerceInventoryMockApi } from "app/mock-api/apps/ecommerce/inventory/api";
import { FileManagerMockApi } from "app/mock-api/apps/file-manager/api";
import { HelpCenterMockApi } from "app/mock-api/apps/help-center/api";
import { IconsMockApi } from "app/mock-api/ui/icons/api";
import { MailboxMockApi } from "app/mock-api/apps/mailbox/api";
import { MessagesMockApi } from "app/mock-api/common/messages/api";
import { NotesMockApi } from "app/mock-api/apps/notes/api";
import { NotificationsMockApi } from "app/mock-api/common/notifications/api";
import { ProjectMockApi } from "app/mock-api/dashboards/project/api";
import { SearchMockApi } from "app/mock-api/common/search/api";
import { ScrumboardMockApi } from "app/mock-api/apps/scrumboard/api";
import { ShortcutsMockApi } from "app/mock-api/common/shortcuts/api";
import { TasksMockApi } from "app/mock-api/apps/tasks/api";
import { UserMockApi } from "app/mock-api/common/user/api";

export const mockApiServices = [
  AcademyMockApi,
  ActivitiesMockApi,
  AnalyticsMockApi,
  AuthMockApi,
  CalendarMockApi,
  ChatMockApi,
  ContactsMockApi,
  ECommerceInventoryMockApi,
  FileManagerMockApi,
  HelpCenterMockApi,
  IconsMockApi,
  MailboxMockApi,
  MessagesMockApi,
  NotesMockApi,
  NotificationsMockApi,
  ProjectMockApi,
  SearchMockApi,
  ScrumboardMockApi,
  ShortcutsMockApi,
  TasksMockApi,
  UserMockApi,
];
