import { Injectable } from "@angular/core";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { Apollo, gql } from "apollo-angular";
import {
  AccountType,
  ForgotPasswordGQL,
  LoginWithPasswordGQL,
  RefreshJwtGQL,
  RegisterGQL,
  ResetPasswordGQL,
} from "../../../generated/graphql";
import { BackendError } from "app/backenderror";
import { Router } from "@angular/router";
import { User } from "../user/user.types";
import { environment } from "environments/environment";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private localstorageChecked = false;
  private refreshTokenTimeout: NodeJS.Timeout;
  /**
   * Constructor
   */
  constructor(
    private _userService: UserService,
    private apollo: Apollo,
    private ApolloForgotPasswordMutation: ForgotPasswordGQL,
    private ApolloResetPasswordMutation: ResetPasswordGQL,
    private loginWithPasswordMutation: LoginWithPasswordGQL,
    private refreshJwtMutation: RefreshJwtGQL,
    private registerMutation: RegisterGQL,
    private _router: Router
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? null;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string) {
    return this.ApolloForgotPasswordMutation.mutate({
      email,
    });
  }

  /**
   * Reset password
   *
   * @param token
   * @param password
   */
  resetPassword(token: string, password: string) {
    return this.ApolloResetPasswordMutation.mutate({
      token,
      newPassword: password,
    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  async signIn(credentials: { email: string; password: string }) {
    if (this._authenticated) {
      throw new Error("User is already logged in.");
    }

    const response = await this.loginWithPasswordMutation
      .mutate({
        email: credentials.email,
        password: credentials.password,
      })
      .toPromise();

    if (response.data.LoginWithPassword?.__typename === "LoginSuccess") {
      this.accessToken = response.data.LoginWithPassword.access_token;
      this._authenticated = true;
      this._userService.user = response.data.LoginWithPassword.user;
      this.startRefreshTokenTimer();
      this.checkisValidBpAccount(this._userService.user);
      return response.data.LoginWithPassword;
    } else {
      return response.data.LoginWithPassword;
    }
  }

  checkisValidBpAccount(user: User) {
    if (user?.account?.accountType !== "b2c" && !user?.superUser) {
      setTimeout(() => {
        window.location.replace(`${environment.publicSiteUrl}/my-account`);
      }, 3000);
    }
  }

  /**
   * Sign in using the access token
   */
  async signInUsingToken() {
    // Renew token
    const response = await this.refreshJwtMutation.mutate().toPromise();
    if (response.data.RefreshJWT.__typename === "InvalidRefreshTokenError") {
      throw new BackendError(response.data.RefreshJWT);
    }
    if (response.data.RefreshJWT.__typename === "NoRefreshTokenProvidedError") {
      throw new BackendError(response.data.RefreshJWT);
    }
    if (response.data?.RefreshJWT?.__typename === "LoginSuccess") {
      this.accessToken = response.data.RefreshJWT.access_token;
      this._authenticated = true;
      this._userService.user = response.data.RefreshJWT.user;
      this.checkisValidBpAccount(this._userService.user);
      return true;
    } else throw new Error("Unexpected case");
  }

  /**
   * Sign out
   */
  async signOut() {
    // Remove the access token from the local storage
    localStorage.removeItem("accessToken");

    // Set the authenticated flag to false
    this._authenticated = false;

    const response = await this.apollo.mutate({
      mutation: gql`
        mutation logout {
          Logout {
            ... on GenericSuccessType {
              success
            }
          }
        }
      `,
    });

    return true;
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    companyName: string;
    abn: string;
    mobileNumber: string;
  }) {
    const postObject = {
      ...user,
      accountType: AccountType.B2c,
      mobileNumber: {
        mobileNumber: user.mobileNumber,
        countryCode: "61", // TODO: remove this hardcode
      },
    };

    return this.registerMutation.mutate(postObject);
  }

  /**
   * Check the authentication status
   */
  check(): boolean {
    // Check if the user is logged in
    this.loadJwt();
    if (!this._authenticated) {
      return false;
    }

    // Check the access token availability
    if (!this.accessToken) {
      return false;
    }

    // Check the access token expire date
    return !AuthUtils.isTokenExpired(this.accessToken);
  }

  private loadJwt() {
    if (this.localstorageChecked) return;
    try {
      const expired = AuthUtils.isTokenExpired(this.accessToken);
      if (expired) return localStorage.removeItem("accessToken");

      // if valid, load the JWT into memory
      this._authenticated = true;
      const userobs = this._userService.get();
      userobs.subscribe((user) => this.checkisValidBpAccount(user));
      this.startRefreshTokenTimer();
    } finally {
      this.localstorageChecked = true;
    }
  }

  showSignIn(redirectURL?: string) {
    this._router.navigate(["sign-in"], { queryParams: { redirectURL } });
  }

  private startRefreshTokenTimer() {
    // clear any existing timer
    if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);

    // set a timeout to refresh the token a minute before jwt expires
    const expires = AuthUtils.getTokenExpirationDate(this.accessToken);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    console.log("JWT: Started refresh timer for", (timeout / 1000 / 60).toFixed(2), "minutes");
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private async refreshToken() {
    console.log("JWT: Refreshing");
    const success = await this.signInUsingToken();
    console.log("JWT: Refreshed successfully");
    this.startRefreshTokenTimer();
  }
}
