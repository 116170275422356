import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";
import { SetupGuard } from "./core/auth/guards/setup.guard";
import { CmsPageComponent } from "./modules/landing/home/cms-page/cms-page.component";

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "dashboard" },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboard" },

  {
    path: "terms",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    loadChildren: () => import("app/modules/legal/terms/terms.module").then((m) => m.TermsModule),
  },

  {
    path: "privacy",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    loadChildren: () => import("app/modules/legal/privacy/privacy.module").then((m) => m.PrivacyModule),
  },
  {
    path: "cancellation-policy",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    loadChildren: () =>
      import("app/modules/legal/cancellation-policy/cancellation-policy.module").then(
        (m) => m.CancellationPolicyModule
      ),
  },
  {
    path: "dispute-resolution-policy",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    loadChildren: () =>
      import("app/modules/legal/dispute-resolution-policy/dispute-resolution-policy.module").then(
        (m) => m.DisputeResolutionPolicyModule
      ),
  },
  {
    path: "review-policy",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    loadChildren: () =>
      import("app/modules/legal/review-policy/review-policy.module").then((m) => m.ReviewPolicyModule),
  },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "forgot-password",
        loadChildren: () =>
          import("app/modules/auth/forgot-password/forgot-password.module").then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: "reset-password/:token",
        loadChildren: () =>
          import("app/modules/auth/reset-password/reset-password.module").then((m) => m.AuthResetPasswordModule),
      },
      {
        path: "sign-in",
        loadChildren: () => import("app/modules/auth/sign-in/sign-in.module").then((m) => m.AuthSignInModule),
      },
      {
        path: "sign-up",
        loadChildren: () => import("app/modules/auth/sign-up/sign-up.module").then((m) => m.AuthSignUpModule),
      },
      {
        path: "approval-required",
        loadChildren: () =>
          import("app/modules/auth/approval-required/approval-required.module").then((m) => m.ApprovalRequiredModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "confirmation-required",
        loadChildren: () =>
          import("app/modules/auth/confirmation-required/confirmation-required.module").then(
            (m) => m.AuthConfirmationRequiredModule
          ),
      },
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.module").then((m) => m.AuthSignOutModule),
      },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () => import("app/modules/landing/home/home.module").then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard, SetupGuard],
    canActivateChild: [AuthGuard, SetupGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "reporting",
        loadChildren: () => import("app/modules/admin/reporting/reporting.module").then((m) => m.ReportingModule),
      },
      // CMS Page
      {
        path: "page/:urlSlug",
        component: CmsPageComponent,
      },
      {
        path: "listings",
        loadChildren: () => import("app/modules/admin/listings/listings.module").then((m) => m.ListingsModule),
      },
      {
        path: "categories",
        loadChildren: () => import("app/modules/admin/categories/categories.module").then((m) => m.CategoriesModule),
      },
      {
        path: "bookings",
        loadChildren: () => import("app/modules/admin/bookings/bookings.module").then((m) => m.BookingsModule),
      },
      {
        path: "my-account",
        loadChildren: () => import("app/modules/my-account/my-account.module").then((m) => m.MyAccountModule),
      },
      {
        path: "super-user",
        loadChildren: () => import("app/modules/superuser/superuser.module").then((m) => m.SuperuserModule),
      },
      // {
      //     path: 'team',
      //     loadChildren: () => import('app/modules/admin/team/team.module').then(m => m.TeamModule)
      // },
      {
        path: "dashboard",
        loadChildren: () => import("app/modules/admin/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
    ],
  },
];
