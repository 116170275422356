import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { AuthService } from "../auth.service";
import { UserService } from "../../user/user.service";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class SetupGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _authService: AuthService, private _userService: UserService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check("/");
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private async _check(redirectURL: string): Promise<boolean> {
    // Check the authentication status
    if (!this._authService.check()) return true;
    const user = await this._userService.user$.toPromise();
    if (!user.setup.contactNumberVerified) {
      // TODO contactnumber queryparam
      const user = this._userService.user;
      this._router.navigate(["/confirmation-required"], {
        queryParams: {
          session: user.contactNumber[0]._id,
        },
      });
      return false;
    }

    return true;
  }
}
