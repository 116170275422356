<div class="flex flex-col flex-auto w-full">
  <div class="flex flex-wrap w-200 max-w-screen-xl mx-auto p-6 md:p-8">
    <div class="sm:col-spaxn-6 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
      <ng-container *ngIf="!loading && !notFound">
        <div class="prose lg:prose-xl mx-auto mt-10 w-200">
          <h2 class="text-3xl mb-4">{{ title }}</h2>
          <div [innerHTML]="addVideoEmbeds(body)"></div>
        </div>
      </ng-container>
      <h2 *ngIf="loading" class="text-lg">Loading..</h2>
      <h2 *ngIf="notFound" class="text-lg">404 Not Found</h2>
    </div>
  </div>
</div>
