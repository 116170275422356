import { FuseNavigationItem } from "@fuse/components/navigation";
import { UserService } from "../user/user.service";
import { Navigation } from "./navigation.types";

export function getNavigationConfig(userService: UserService): Navigation {
  const navigationData: FuseNavigationItem[] = [
    {
      id: "su_group",
      type: "group",
      title: "Releaseit",
      subtitle: "Superuser Admin",
      hidden: (item) => !userService.user.superUser,
      children: [
        {
          id: "superuser_accounts",
          title: "Accounts",
          type: "basic",
          icon: "heroicons_outline:user-group",
          link: "/super-user/account",
        },
        {
          id: "superuser_approve_listings",
          title: "Approve Listings",
          type: "basic",
          icon: "heroicons_outline:badge-check",
          link: "/super-user/unapproved-listings",
        },
        {
          id: "superuser_all_categories",
          title: "All Categories",
          type: "basic",
          icon: "heroicons_outline:view-boards",
          link: "/super-user/categories",
        },
        {
          id: "superuser_all_listings",
          title: "All Listings",
          type: "basic",
          icon: "heroicons_outline:collection",
          link: "/super-user/listings",
        },
        {
          id: "superuser_bookings",
          title: "Bookings Admin",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/bookings/super-user",
        },
      ],
    },
    {
      id: "top_group",
      type: "group",
      title: userService.user?.account?.accountName,
      subtitle: userService.user?.account?.business?.name,
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:home",
          link: "/dashboard",
        },
        {
          id: "categories",
          title: "Your Categories",
          type: "basic",
          icon: "heroicons_outline:view-boards",
          link: "/categories",
        },
        {
          id: "listings",
          title: "Your Listings",
          type: "basic",
          icon: "heroicons_outline:collection",
          link: "/listings",
        },
        {
          id: "reporting",
          title: "Reporting",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/reporting",
        },
        {
          id: "tutorials",
          title: "Tutorials",
          type: "basic",
          icon: "heroicons_outline:academic-cap",
          link: "/page/bp-tutorials-home",
        },
      ],
    },
  ];

  return {
    compact: navigationData,
    default: navigationData,
    futuristic: navigationData,
    horizontal: navigationData,
  };
}
