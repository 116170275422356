import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpBatchLink } from "apollo-angular/http";
import { ApolloLink, InMemoryCache } from "@apollo/client/core";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "./core/auth/auth.service";
import { CategoryService } from "./core/listing/category.service";

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

@NgModule({
  exports: [],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    HttpClientModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    // TODO: remove comment
    // {
    //     provide : HTTP_INTERCEPTORS,
    //     useClass: AuthInterceptor,
    //     multi   : true
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpBatchLink) => {
        const http = httpLink.create({
          uri: environment.graphql,
          withCredentials: true,
          batchInterval: 10,
          batchMax: 20,
        });
        const middleware = new ApolloLink((operation, forward) => {
          const accessToken = localStorage.getItem("accessToken");
          if (accessToken)
            operation.setContext({
              headers: new HttpHeaders()
                .set("Authorization", `Bearer ${accessToken}`)
                .append("apollographql-client-name", "business-portal"),
            });
          return forward(operation);
        });

        const error = onError(({ networkError, response }) => {
          if (typeof response === "undefined") return;
          if (!response.errors) return;

          const unauthError = response.errors.find((e) => {
            e.message === "UNAUTHORIZED";
          });

          if (unauthError) {
          }
        });

        const link = middleware.concat(error).concat(http);

        return {
          cache: new InMemoryCache(),
          link,
        };
      },
      deps: [HttpBatchLink],
    },
    CategoryService,
  ],
})
export class AppModule {}
