export class BackendError extends Error {
  errorCode: string;
  errorGqlType?: string;

  constructor(error: { code: string; message: string; __typename?: string }) {
    super(error.message);
    this.errorCode = error.code;
    this.errorGqlType = error.__typename;
    Object.setPrototypeOf(this, BackendError.prototype);
  }
}
