import { Injectable } from "@angular/core";
import { from, Observable, ReplaySubject } from "rxjs";
import { Navigation } from "app/core/navigation/navigation.types";
import { getNavigationConfig } from "./data";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    const config = getNavigationConfig(this.userService);
    this._navigation.next(config);
    return from([config]);
  }
}
