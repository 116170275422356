import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { UserService } from "./core/user/user.service";

@Injectable({
  providedIn: "root",
})
export class SuperuserService {
  constructor(private userService: UserService) {}

  get superUser$() {
    const user = this.userService.user$;
    return user.pipe(map((v) => v.superUser || false));
  }
}
